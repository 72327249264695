document.addEventListener("DOMContentLoaded", function () {
    var nav = document.querySelector("header nav");

    if (nav) {
        var toggler = document.querySelector("header .header-nav-toggler");

        if (toggler) {
            toggler.addEventListener("click", function () {
                nav.classList.toggle("open");
            });
        }
    }
});
